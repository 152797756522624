import { zodResolver } from "@hookform/resolvers/zod";
import { capitalize } from "lodash";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { ButtonSelect, MultiSelect } from "@ag/design-system/molecules";
import {
  ButtonSelectField,
  InputField,
  MultiSelectField,
} from "@ag/form-fields";

import { useCreateCropPropertiesMutation } from "../api/create-crop-properties";
import { useUpdateCropPropertiesMutation } from "../api/update-crop-properties";
import { IpccProperties } from "../entities/crop-details";

const ipccFormSchema = () =>
  z.object({
    net_gross: z.number(),
    show_residue: z.boolean(),
    gross_yield_min: z.number(),
    gross_yield_max: z.number(),
    kg_n_ha_min: z.number(),
    kg_n_ha_max: z.number(),
    methodology_version: z.number(),
    dry_matter_fraction: z.string(),
    slope: z.number(),
    intercept: z.number(),
    categories: z.array(z.string()).optional(),
  });

type IpccFormData = z.infer<ReturnType<typeof ipccFormSchema>>;

const IpccForm = ({
  id,
  harvestYear,
  properties,
}: {
  id: string;
  harvestYear: string;
  properties: Partial<IpccProperties>;
}) => {
  const [editing, setEditing] = useState(false);
  const { handleSubmit, control, register, reset } = useForm<IpccFormData>({
    resolver: zodResolver(ipccFormSchema()),
    defaultValues: properties,
  });
  const createCropProperties = useCreateCropPropertiesMutation();
  const updateCropProperties = useUpdateCropPropertiesMutation();

  const enableEditing = () => setEditing(true);

  const handleCancelEdit = () => {
    setEditing(false);
    reset(properties);
  };

  const postIpccFormData = (data: IpccFormData) => {
    if (Object.keys(properties).length === 0) {
      createCropProperties.mutate(
        { id, harvestYear, methodology: "biomass", properties: data },
        { onSuccess: () => setEditing(false) },
      );
    } else {
      updateCropProperties.mutate(
        { id, harvestYear, methodology: "biomass", properties: data },
        { onSuccess: () => setEditing(false) },
      );
    }
  };

  return (
    <div>
      <h2 className="mb-4 text-h2">IPCC Properties</h2>
      <form onSubmit={handleSubmit(postIpccFormData)}>
        <section className="mb-4 grid grid-cols-2 items-end gap-x-8 gap-y-4">
          <InputField
            {...register("net_gross", {
              setValueAs: v => (v === "" ? undefined : Number(v)),
            })}
            label="Net Gross"
            type="number"
            isDisabled={!editing}
          />

          <Controller
            name={"show_residue"}
            control={control}
            render={({ field }) => (
              <ButtonSelectField
                {...field}
                label="Show Residue"
                isDisabled={!editing}
              >
                <ButtonSelect.Option value={true}>Yes</ButtonSelect.Option>
                <ButtonSelect.Option value={false}>No</ButtonSelect.Option>
              </ButtonSelectField>
            )}
          />

          <InputField
            {...register("gross_yield_min", {
              setValueAs: v => (v === "" ? undefined : Number(v)),
            })}
            label="Gross Yield Minimum"
            type="number"
            isDisabled={!editing}
          />

          <InputField
            {...register("gross_yield_max", {
              setValueAs: v => (v === "" ? undefined : Number(v)),
            })}
            label="Gross Yield Maximum"
            type="number"
            isDisabled={!editing}
          />

          <InputField
            {...register("kg_n_ha_min", {
              setValueAs: v => (v === "" ? undefined : Number(v)),
            })}
            label="Kg N Ha Minimum"
            type="number"
            isDisabled={!editing}
          />

          <InputField
            {...register("kg_n_ha_max", {
              setValueAs: v => (v === "" ? undefined : Number(v)),
            })}
            label="Kg N Ha Maximum"
            type="number"
            isDisabled={!editing}
          />

          <InputField
            {...register("methodology_version", {
              setValueAs: v => (v === "" ? undefined : Number(v)),
            })}
            label="Methodology Version"
            type="number"
            isDisabled={!editing}
          />

          <InputField
            {...register("dry_matter_fraction")}
            label="Dry Matter Fraction"
            isDisabled={!editing}
          />

          <InputField
            {...register("slope", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            label="Slope"
            type="number"
            step=".01"
            isDisabled={!editing}
          />

          <InputField
            {...register("intercept", {
              setValueAs: v => (v === "" ? undefined : Number(v)),
            })}
            label="Intercept"
            type="number"
            value={properties.intercept}
            isDisabled={!editing}
          />

          <Controller
            name={"show_residue"}
            control={control}
            render={({ field }) => (
              <MultiSelectField
                {...field}
                control={control}
                disabled={!editing}
                label="Categories"
                name="categories"
                placeholder="select categories..."
                renderSelection={selection =>
                  selection?.map(({ textValue }) => textValue).join(", ")
                }
              >
                {/* TODO => get all categories */}
                {properties.categories?.map(category => (
                  <MultiSelect.Item key={category}>
                    {capitalize(category)}
                  </MultiSelect.Item>
                ))}
              </MultiSelectField>
            )}
          />
        </section>
        {editing && (
          <div className="flex gap-2">
            <Button type="submit">Update</Button>
            <Button
              type="button"
              variant="secondary"
              onClick={handleCancelEdit}
            >
              Cancel
            </Button>
          </div>
        )}{" "}
      </form>
      {!editing && <Button onClick={enableEditing}>Edit</Button>}
    </div>
  );
};

export default IpccForm;
