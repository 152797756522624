import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import { InputField } from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import { useCreateCropMutation } from "../api/create-crop";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const NewCropSchema = z.object({
  cropName: z.string().min(1, "Crop name is required"),
});

type NewCropData = z.infer<typeof NewCropSchema>;

const AddNewCropModal = ({ isOpen, onClose }: Props) => {
  const createCrop = useCreateCropMutation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<NewCropData>({
    resolver: zodResolver(NewCropSchema),
  });

  const handleNewCropSubmit = (data: NewCropData) => {
    createCrop.mutate(
      { name: data.cropName },
      {
        onSuccess: () => {
          ToastNotification.success(`New Crop ${data.cropName} added`);
          onClose();
        },
      },
    );
  };

  return (
    <Modal.Root isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header>
        <Modal.Title>Add a new crop:</Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <form
          onSubmit={handleSubmit(handleNewCropSubmit)}
          className="flex flex-col gap-4"
        >
          <InputField
            {...register("cropName")}
            label="Crop Name"
            error={errors.cropName}
          />
          <Button type="submit" className="self-end">
            Add crop
          </Button>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};

export default AddNewCropModal;
