import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";

import { CropDetails } from "../entities/crop-details";
import { generateCropByHarvestYearQueryKey } from "./get-crop-harvest-year";

export const createCropProperties = async ({
  id,
  methodology,
  harvestYear,
  properties,
}: {
  id: string;
  methodology: "ipcc" | "biomass";
  harvestYear: string;
  properties: object;
}) => {
  const response = await axios.post<CropDetails>(
    `/v1/crops/${methodology}/${id}/harvest_year/${harvestYear}`,
    properties,
    {
      apiSource: "crops-fertilisers",
    },
  );

  return response.data;
};

export const useCreateCropPropertiesMutation = () =>
  useMutation(createCropProperties, {
    onSuccess: (_, { id, harvestYear }) => {
      queryClient.invalidateQueries(
        generateCropByHarvestYearQueryKey(id, harvestYear),
      );
    },
    onError: error => ToastNotification.error(error),
  });
