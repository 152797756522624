import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";

import { CropDetails } from "../entities/crop-details";
import { generateCropsQueryKey } from "./get-crops";

export const createCrop = async (data: { name: string }) => {
  const response = await axios.post<CropDetails>(`/v1/crops/`, data, {
    apiSource: "crops-fertilisers",
  });

  return response.data;
};

export const useCreateCropMutation = () =>
  useMutation(createCrop, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateCropsQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
