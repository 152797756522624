import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { InputField } from "@ag/form-fields";

import { useCreateCropPropertiesMutation } from "../api/create-crop-properties";
import { useUpdateCropPropertiesMutation } from "../api/update-crop-properties";
import { BiomassProperties } from "../entities/crop-details";

const biomassFormSchema = () =>
  z.object({
    "n-fix": z.number(),
    "N content of above-ground residues (N AG(T))": z.number(),
    "N-AG uncertainty": z.string(),
    "N content of below-ground residues (N BG(T))": z.number(),
    "N-BG uncertainty": z.string(),
    "Ratio of above-ground residue dry matter to harvested yield (RAG (T))":
      z.number(),
    "Ratio of below-ground biomass to above-ground biomass (RS (T))":
      z.number(),
    "ratio uncertainty": z.string(),
    "Dry matter fraction of harvested product (DRY)": z.number(),
    "Slope (T)": z.number(),
    "slope ± 2 s.d. as % of mean": z.string(),
    "Intercept (T)": z.number(),
    "Intercept ± 2 s.d. as % of mean": z.string(),
    "R2 adj.": z.number(),
  });

type BiomassFormData = z.infer<ReturnType<typeof biomassFormSchema>>;

const BiomassForm = ({
  id,
  harvestYear,
  properties,
}: {
  id: string;
  harvestYear: string;
  properties: Partial<BiomassProperties>;
}) => {
  const [editing, setEditing] = useState(false);
  const { handleSubmit, register, reset } = useForm<BiomassFormData>({
    resolver: zodResolver(biomassFormSchema()),
    defaultValues: properties,
  });
  const updateCropProperties = useUpdateCropPropertiesMutation();
  const createCropProperties = useCreateCropPropertiesMutation();

  const enableEditing = () => setEditing(true);

  const handleCancelEdit = () => {
    setEditing(false);
    reset(properties);
  };

  const postBiomassFormData = (data: BiomassFormData) => {
    if (Object.keys(properties).length === 0) {
      createCropProperties.mutate(
        { id, harvestYear, methodology: "biomass", properties: data },
        { onSuccess: () => setEditing(false) },
      );
    } else {
      updateCropProperties.mutate(
        { id, harvestYear, methodology: "biomass", properties: data },
        { onSuccess: () => setEditing(false) },
      );
    }
  };

  return (
    <div>
      <h2 className="mb-4 text-h2">Biomass Properties</h2>
      <form onSubmit={handleSubmit(postBiomassFormData)}>
        <section className="mb-4 grid grid-cols-2 items-end gap-x-8 gap-y-4">
          <InputField
            {...register("n-fix", {
              setValueAs: v => (v === "" ? undefined : Number(v)),
            })}
            label="N-fix"
            type="number"
            isDisabled={!editing}
          />

          <InputField
            {...register("N content of above-ground residues (N AG(T))", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            label="N content of above-ground residues (N AG(T))"
            type="number"
            step="0.0001"
            isDisabled={!editing}
          />

          <InputField
            {...register("N-AG uncertainty")}
            label="N-AG uncertainty"
            type="string"
            isDisabled={!editing}
          />

          <InputField
            {...register("N content of below-ground residues (N BG(T))", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            step="0.0001"
            label="N content of below-ground residues (N BG(T))"
            type="number"
            isDisabled={!editing}
          />

          <InputField
            {...register("N-BG uncertainty")}
            label="N-BG uncertainty"
            type="string"
            isDisabled={!editing}
          />

          <InputField
            {...register(
              "Ratio of above-ground residue dry matter to harvested yield (RAG (T))",
              {
                setValueAs: v => (v === "" ? undefined : parseFloat(v)),
              },
            )}
            step="0.01"
            label="Ratio of above-ground residue dry matter to harvested yield (RAG (T))"
            type="number"
            isDisabled={!editing}
          />

          <InputField
            {...register(
              "Ratio of below-ground biomass to above-ground biomass (RS (T))",
              {
                setValueAs: v => (v === "" ? undefined : parseFloat(v)),
              },
            )}
            step="0.01"
            label="Ratio of below-ground biomass to above-ground biomass (RS (T))"
            type="number"
            isDisabled={!editing}
          />

          <InputField
            {...register("ratio uncertainty")}
            label="Ratio Uncertainty"
            type="string"
            isDisabled={!editing}
          />

          <InputField
            {...register("Dry matter fraction of harvested product (DRY)", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            step="0.01"
            label="Dry matter fraction of harvested product (DRY)"
            type="number"
            isDisabled={!editing}
          />

          <InputField
            {...register("Slope (T)", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            step="0.01"
            label="Slope (T)"
            type="number"
            isDisabled={!editing}
          />

          <InputField
            {...register("slope ± 2 s.d. as % of mean")}
            label="Slope ± 2 s.d. as % of mean"
            type="string"
            isDisabled={!editing}
          />

          <InputField
            {...register("Intercept (T)", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            step="0.01"
            label="Intercept (T)"
            type="number"
            isDisabled={!editing}
          />

          <InputField
            {...register("Intercept ± 2 s.d. as % of mean")}
            label="Intercept ± 2 s.d. as % of mean"
            type="string"
            isDisabled={!editing}
          />

          <InputField
            {...register("R2 adj.", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            step="0.01"
            label="R2 adj."
            type="number"
            isDisabled={!editing}
          />
        </section>

        {editing && (
          <div className="flex gap-2">
            <Button type="submit">Update</Button>
            <Button
              type="button"
              variant="secondary"
              onClick={handleCancelEdit}
            >
              Cancel
            </Button>
          </div>
        )}
      </form>
      {!editing && <Button onClick={enableEditing}>Edit</Button>}
    </div>
  );
};

export default BiomassForm;
